import { FC, useEffect, useState } from "react";

import { Button, Divider, Flex, Input, Menu } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

import axiosInstance from "../axiosInstance";

interface SearchFilterProps {
  field: string;
  collectionId: string;
  dropdownProps: FilterDropdownProps;
}

const SearchFilter: FC<SearchFilterProps> = ({
  field,
  collectionId,
  dropdownProps,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchResult, setSearchResult] = useState<string[]>([]);

  useEffect(() => {
    const filterField = async (filter: string) => {
      const response = await axiosInstance.get(
        `https://melcarnedirectus2.digipulse.ch/items/${collectionId}?aggregate[count]=*&meta=filter_count&groupBy=${field}&limit=100${
          filter ? `&filter[${field}][_icontains]=${filter}` : ""
        }`
      );
      setSearchResult(response.data.data.map((value: any) => value[field]));
    };

    filterField(searchText);
  }, [searchText, field, collectionId]);

  return (
    <Flex vertical style={{ padding: 8, width: 250 }}>
      <Input
        placeholder="Résultats limités à 50 éléments)"
        onChange={(event) => setSearchText(event.target.value)}
        allowClear
      />
      <Divider style={{ marginTop: 8, marginBottom: 0 }} />
      <Menu
        selectedKeys={dropdownProps.selectedKeys as string[]}
        items={searchResult.map((value) => ({
          key: value,
          label: value,
        }))}
        multiple
        style={{ maxHeight: 150 }}
        onSelect={({ selectedKeys }) =>
          dropdownProps.setSelectedKeys(selectedKeys)
        }
        onDeselect={({ selectedKeys }) =>
          dropdownProps.setSelectedKeys(selectedKeys)
        }
      />
      <Divider style={{ marginTop: 0, marginBottom: 8 }} />
      <Flex justify="space-between">
        <Button
          onClick={() => dropdownProps.setSelectedKeys([])}
          type="link"
          size="small"
          disabled={dropdownProps.selectedKeys.length === 0}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => dropdownProps.confirm()}
          type="primary"
          size="small"
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );
};

export default SearchFilter;
