import { FC, useEffect, useState } from "react";

import { Button, Divider, Flex, InputNumber, Slider } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

import axiosInstance from "../axiosInstance";

interface NumberFilterProps {
  field: string;
  collectionId: string;
  dropdownProps: FilterDropdownProps;
}

const NumberFilter: FC<NumberFilterProps> = ({
  field,
  collectionId,
  dropdownProps,
}) => {
  const [filterInterval, setFilterInterval] = useState<
    [min: number, max: number]
  >([0, 0]);
  const [selectedNumberRange, setSelectedNumberRange] =
    useState<[start: number, end: number]>(filterInterval);

  useEffect(() => {
    const getFilterInterval = async () => {
      const response = await axiosInstance.get(
        `https://melcarnedirectus2.digipulse.ch/items/${collectionId}?aggregate[max]=${field}&aggregate[min]=${field}`
      );
      const min = +`${Math.floor(response.data.data[0].min[field] / 10)}0`;
      const max = +`${Math.ceil(response.data.data[0].max[field] / 10)}0`;

      setFilterInterval([min, max]);
    };

    getFilterInterval();
  }, [collectionId, field]);

  useEffect(() => {
    if (dropdownProps.selectedKeys.length > 0) {
      setSelectedNumberRange([
        dropdownProps.selectedKeys[0] as number,
        dropdownProps.selectedKeys[1] as number,
      ]);
    } else {
      setSelectedNumberRange(filterInterval);
    }
  }, [dropdownProps.selectedKeys, filterInterval]);

  return (
    <Flex vertical gap={8} style={{ padding: 8, width: 300 }}>
      <Flex vertical style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Slider
          min={filterInterval[0]}
          max={filterInterval[1]}
          range
          value={selectedNumberRange}
          onChange={(value) => dropdownProps.setSelectedKeys(value)}
          step={10}
          marks={{
            [filterInterval[0]]: filterInterval[0],
            [filterInterval[1]]: filterInterval[1],
          }}
        />
        <Flex justify="space-between" style={{ width: "100%" }}>
          <InputNumber
            style={{ width: 100 }}
            value={dropdownProps.selectedKeys[0] as number}
            size="small"
            onChange={(value) =>
              dropdownProps.setSelectedKeys([
                value as number,
                dropdownProps.selectedKeys[1],
              ])
            }
            step={1}
            type="number"
          />
          <InputNumber
            style={{ width: 100 }}
            value={dropdownProps.selectedKeys[1] as number}
            size="small"
            onChange={(value) =>
              dropdownProps.setSelectedKeys([
                dropdownProps.selectedKeys[0],
                value as number,
              ])
            }
            step={1}
            type="number"
          />
        </Flex>
      </Flex>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Flex justify="space-between">
        <Button
          onClick={() => dropdownProps.setSelectedKeys([])}
          type="link"
          size="small"
          disabled={dropdownProps.selectedKeys.length === 0}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => dropdownProps.confirm()}
          type="primary"
          size="small"
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );
};

export default NumberFilter;
