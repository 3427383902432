import { FC, useEffect, useRef } from "react";

import { Input, InputRef } from "antd";
import { debounce } from "lodash";

import { FieldType } from "../services/collectionFields";

const FieldColumnFilter: FC<{
  name: string;
  type: FieldType;
  value: string[];
  onFiltered: (filter: string) => void;
}> = ({ name, value, onFiltered, type }) => {
  const input = useRef<InputRef>(null);

  useEffect(() => {
    if (input.current?.input) {
      input.current.input.value =
        value && value.length === 1 && value[0].includes("***")
          ? value[0].replaceAll("*", "")
          : "";
    }
  }, [value]);

  const debounceOnChange = debounce(
    (event) => onFiltered(event.target.value),
    1000
  );

  if (type === "float" || type === "integer") return null;

  return (
    <Input
      ref={input}
      name={name}
      size="small"
      onChange={debounceOnChange}
      allowClear
    />
  );
};

export default FieldColumnFilter;
