import { FC, useEffect, useState } from "react";

import { Button, Modal, Table, Typography, message } from "antd";

import axiosInstance from "../axiosInstance";

interface ValidateUpdatesProps {
  ids: string[];
}

const ValidateUpdates: FC<ValidateUpdatesProps> = ({ ids }) => {
  const [openValidationMaj, setOpenValidationMaj] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchSelectedRowsData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `https://melcarnedirectus2.digipulse.ch/items/transactions_updates?limit=200000&fields[]=id,Requete,Adresse,NoParcelle&sort[]=-NoParcelle&filter[id][_in]=${ids.join(
            ","
          )}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchSelectedRowsData();
  }, [ids]);

  return (
    <>
      {contextHolder}
      <Button
        style={{ backgroundColor: "#fff176" }}
        onClick={() => setOpenValidationMaj(!openValidationMaj)}
        disabled={ids.length === 0}
      >
        {`Valider les MAJ${ids.length > 0 ? ` (${ids.length})` : ""}`}
      </Button>

      <Modal
        open={openValidationMaj}
        closable={false}
        onCancel={() => setOpenValidationMaj(!openValidationMaj)}
        title="Validations des mises à jour"
        okText="Valider"
        cancelText="Annuler"
        width={800}
        loading={loading}
        onOk={async () => {
          setLoading(true);
          try {
            await Promise.all(
              ids.map((key) =>
                axiosInstance.post(
                  `https://melcarnebackend.digipulse.ch/validate_transaction_update`,
                  { body: { key } }
                )
              )
            );
            messageApi.open({
              type: "success",
              content: "Les mises à jour ont été effectuées avec succès.",
            });
          } catch (error) {
            console.log(error);
            messageApi.open({
              type: "error",
              content: "Une erreur est survenue lors des mises à jour.",
            });
          } finally {
            setLoading(false);
          }
        }}
      >
        <Table
          dataSource={data}
          size="small"
          title={() => (
            <Typography.Text strong>
              Etes-vous sûre de valider les mises à jour suivantes? <br />
              Une fois executées, les anciennes données seront écrasées.
            </Typography.Text>
          )}
        >
          {["id", "NoParcelle", "Requete", "Adresse"].map((field) => (
            <Table.Column
              key={field}
              title={field}
              dataIndex={field}
              width={field !== "Adresse" ? "auto" : 300}
              ellipsis={true}
            />
          ))}
        </Table>
      </Modal>
    </>
  );
};

export default ValidateUpdates;
