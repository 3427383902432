import { useEffect, useState } from "react";

import { Card, Flex, Statistic } from "antd";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

import axiosInstance from "./axiosInstance";
import { Collection } from "./services/collections";

const Collections = () => {
  const [loading, setLoading] = useState(false);
  const [collectionCount, setCollectionCount] = useState<
    Record<string, string>
  >({});
  const { collections } = useLoaderData() as { collections: Collection[] };

  useEffect(() => {
    const fetchCollectionsCount = async () => {
      setLoading(true);
      const responses = await Promise.all(
        collections.map((collection) =>
          axiosInstance.get(
            `https://melcarnedirectus2.digipulse.ch/items/${collection.name}?aggregate[count]=*`
          )
        )
      );

      responses.forEach(async (response, index) => {
        const result = response.data.data[0].count;

        setCollectionCount((collectionCount) => ({
          ...collectionCount,
          [collections[index].name]: result,
        }));
      });

      setLoading(false);
    };

    fetchCollectionsCount();
  }, [collections]);

  return (
    <Flex wrap={true} style={{ padding: 32 }} justify="space-between" gap={32}>
      {collections.map(({ name }) => (
        <Card key={name} style={{ width: "30%" }} bordered={false}>
          <Link to={`${name}`}>
            <Statistic
              title={name}
              value={collectionCount[name]}
              loading={loading}
            />
          </Link>
        </Card>
      ))}
    </Flex>
  );
};

export default Collections;
